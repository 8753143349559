<template id="e-auto-complete">
  <div
    class="autocomplete-form"
    @keydown.down="onKeydown($event)"
    @keydown.up="onKeyup($event)"
    @keydown.enter="onEnterClicked"
    @focusin="visible = true"
    @focusout="onFocusout($event)"
  >
    <div class="autocomplete-form-input-elements">
      <input
        id="autocomplete-form-input"
        autocomplete="off"
        class="w-100"
        @input="onInputHandler"
        v-model="inputText"
        :readonly="readOnly"
        :disabled="disabled"
        :placeholder="plaholder"
        type="text"
        ref="autoInput"
      />
    </div>
    <template v-if="focusForce">
      <div
        :id="elemId"
        v-show="valuesShowing.length > 0"
        :style="{ maxHeight: '300px', position: 'absolute' }"
        :class="{ hide: (disabled && visible) || !visible }"
      >
        <div
          class="list-group list-group-flush autocomplete-form-items"

          :style="{ maxHeight: '255px', position: 'relative' }"
        >
          <button
            v-for="(vo, index) in valuesShowing.slice(0, -1)"
            :key="index"
            class="list-group-item list-group-item-action"
            :class="{ active: selectedIndex == index }"
            @click="onClickHandler($event, vo.cd, vo.cdNm)"
            @mouseover="selectedIndex = index"
            type="button"
            v-html="vo.displayText"
          ></button>
        </div>
        <button
          class="list-group-item list-group-item-action"
          :style="{ width: '300px', zIndex : '1' }"
          type="button"
          :class="{ active: selectedIndex == valuesShowing.length - 1 }"
          @mouseover="selectedIndex = valuesShowing.length - 1"
          @click="onClickHandler($event, valuesShowing[valuesShowing.length - 1].cd, valuesShowing[valuesShowing.length - 1].cdNm)"
          v-html="getLastButtonText()"
        />
      </div>
    </template>
    <template v-else>
      <div
        :id="elemId"
        class="list-group list-group-flush autocomplete-form-items"
        :class="{ hide: (disabled && visible) || !visible }"
        v-show="valuesShowing.length > 0"
      >
        <button
          v-for="(vo, index) in valuesShowing"
          :key="index"
          class="list-group-item list-group-item-action"
          :class="{ active: selectedIndex == index }"
          @click="onClickHandler($event, vo.cd, vo.cdNm)"
          @mouseover="selectedIndex = index"
          type="button"
          v-html="vo.displayText"
        ></button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EAutoComplete',
  props: {
    values: { type: Array, required: true },
    value: { type: [String, Object], default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    isChar: { type: Boolean, default: false, required: false },
    isCharAlert: { type: Boolean, default: false, required: false },
    isFilter: { type: Boolean, default: true, required: false },
    isDebug: { type: Boolean, default: false, required: false },
    plaholder: { type: String, default: '', required: false },
    focusForce: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      inputText: '',
      valuesShowing: [],
      visible: false,
      selectedIndex: 0,
      clickCount: 0,
      isEnter: false
    }
  },
  watch: {
    value () {
      let v =
        typeof this.$props.value === 'object'
          ? this.$props.value.cdNm
          : this.$props.value
      if (this.inputText !== v) {
        this.inputText = v
      }
      // this.inputText = this.$props.value
    },
    values: function () {
      const tx = this.inputText.toLowerCase()
      this.printLog('watch')
      let valuesList

      if (this.$props.isFilter) {
        valuesList = this.$props.values
          .filter((vo) => vo.cdNm.toLowerCase().includes(tx))
          .sort((a, b) => {
            if (b.cd === '') {
              return -1
            }
            const a1 = a.cdNm.toLowerCase()
            const b1 = b.cdNm.toLowerCase()
            if (a1.startsWith(tx) && b1.startsWith(tx)) {
              if (a1 < b1) return -1
              else if (a1 > b1) return 1
              else return 0
            } else if (a1.startsWith(tx)) {
              return -1
            } else if (b1.startsWith(tx)) {
              return 1
            } else {
              if (a1 < b1) return -1
              else if (a1 > b1) return 1
              else return 0
            }
          })
          .map((obj) => {
            if (obj.cd !== '' && this.inputText) {
              obj.displayText = this.changeString(obj.displayText)
            }
            return obj
          })
      } else {
        valuesList = this.$props.values.map((obj) => {
          if (obj.cd !== '' && this.inputText) {
            obj.displayText = this.changeString(obj.displayText)
          }
          return obj
        })
      }

      this.$data.valuesShowing = valuesList
      this.selectedIndex = 0
      this.setBoxScrollTop(0)
    }
  },
  created () {
    this.elemId =
      'autocomplete_' +
      new Date().getTime() +
      '_' +
      Math.floor(Math.random() * 10000)
    if (this.inputText !== this.$props.value) {
      this.inputText = this.$props.value
    }
  },
  methods: {
    getLastButtonText () {
      if (this.valuesShowing.length > 0) {
        return this.valuesShowing[this.valuesShowing.length - 1].displayText
      } else {
        return ''
      }
    },
    changeString (str) {
      if (str && this.inputText) {
        let chgStr = []
        let isWhile = true
        while (isWhile) {
          const obj = str.toLowerCase().match(this.inputText.toLowerCase())
          if (!obj) {
            chgStr.push(str)
            isWhile = false
          } else {
            const txt =
              '<b>' +
              str.substring(obj.index, obj.index + this.inputText.length) +
              '</b>'
            chgStr.push(str.substring(0, obj.index) + txt)
            str = str.substring(obj.index + this.inputText.length)
          }
        }
        return chgStr.join('')
      } else {
        return ''
      }
    },
    onKeydown () {
      console.log('@@@@ onKeydown')
      if (this.valuesShowing.length === 0) {
        return
      }
      this.selectedIndex =
        this.selectedIndex < this.valuesShowing.length - 1
          ? this.selectedIndex + 1
          : this.selectedIndex
      // 스크롤 처리
      const elem = document.querySelector('#' + this.elemId)
      const btn = elem.querySelectorAll('.list-group-item')[this.selectedIndex]
      const bb = btn.offsetTop + btn.offsetHeight
      const h = elem.offsetHeight

      if (this.selectedIndex === 0) {
        elem.scrollTop = 0
      } else if (h < bb) {
        elem.scrollTop = bb - h
      }
    },
    onKeyup () {
      if (this.valuesShowing.length === 0) {
        return
      }
      this.selectedIndex =
        this.selectedIndex > 0 ? this.selectedIndex - 1 : this.selectedIndex
      // 스크롤 처리
      const elem = document.querySelector('#' + this.elemId)
      const btn = elem.querySelectorAll('.list-group-item')[this.selectedIndex]
      const bt = btn.offsetTop

      if (bt < elem.scrollTop) {
        elem.scrollTop = bt
      }
    },
    setBoxScrollTop (y) {
      const elem = document.querySelector('#' + this.elemId)
      elem.scrollTop = y
    },
    onFocusout (e) {
      console.log('@@@@ enter onFocusout: ', e)
      console.log('@@@@ inputText: ', this.inputText)
      console.log('@@@@ this.clickCount: ', this.clickCount)
      this.printLog('onFocusout')
      this.printLog(e)
      if (this.focusForce) {
        //
        try {
          if (e.relatedTarget === null) {
            //
            if (this.clickCount > 0) {
              this.visible = false
              this.selectedIndex = 0
              this.clickCount = 0
              this.setBoxScrollTop(0)
            } else {
              if (this.inputText === '') {
                this.visible = false
                this.selectedIndex = 0
                this.clickCount = 0
                this.setBoxScrollTop(0)
              } else {
                console.log('@@@@ here')
                console.log('@@@@ this.isEnter2: ', this.isEnter)
                if (this.isEnter) {
                  this.visible = false
                  this.selectedIndex = 0
                  this.clickCount = 0
                  this.setBoxScrollTop(0)
                  this.isEnter = false
                } else {
                  this.$refs.autoInput.focus()
                }
              }
            }
          } else {
            const cls = e.relatedTarget.getAttribute('class')
            console.log('@@@@ cls: ', cls)
            if (cls.indexOf('list-group-item-action') > 0) {
              this.clickCount++
              // this.visible = false
              // this.selectedIndex = 0
              // this.setBoxScrollTop(0)
              // return
            } else {
              if (this.inputText === '') {
                this.visible = false
                this.selectedIndex = 0
                this.clickCount = 0
                this.setBoxScrollTop(0)
              } else {
                this.$refs.autoInput.focus()
              }
            }
          }
        } catch (e) {
          console.error('ERR: ', e)
          if (this.inputText === '') {
            this.visible = false
            this.selectedIndex = 0
            this.clickCount = 0
            this.setBoxScrollTop(0)
          } else {
            this.$refs.autoInput.focus()
          }
        }
      } else {
        if (e.relatedTarget === null || e.relatedTarget === undefined) {
          this.visible = false
          this.selectedIndex = 0
          this.setBoxScrollTop(0)
          return
        }

        try {
          const cls = e.relatedTarget.getAttribute('class')
          console.log('@@@@ focus 1')
          if (cls.indexOf('list-group-item-action') === -1) {
            console.log('@@@@ focus 2')
            this.visible = false
            this.selectedIndex = 0
            this.setBoxScrollTop(0)
          }
        } catch (ex) {
          console.log('@@@@ focus 3')
          this.visible = false
          this.selectedIndex = 0
          this.setBoxScrollTop(0)
        }
      }
    },
    onInputHandler: function (e) {
      console.log('@@@@ enter onInputHandler: ', e.target.value)
      let isOk = true
      if (this.$props.isChar) {
        if (
          this.$props.isCharAlert &&
          /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(e.target.value)
        ) {
          this.$ekmtcCommon.alertCallback(
            this.$t('msg.MAIN010G030.035'),
            () => {
              $(e.target).focus().click()
            },
            true
          )
          isOk = false
        }
        let val = this.$ekmtcCommon.removeHangle(e.target.value)
        const arr = []
        let str
        let c
        for (let i = 0; i < val.length; i++) {
          str = val.charAt(i)
          c = val.charCodeAt(i)
          if (
            '#?~`$_|{}[]!^\\"-\\+='.indexOf(str) < 0 &&
            ((c >= 0 && c <= 91) || (c >= 93 && c <= 127))
          ) {
            arr.push('' + str)
          }
        }

        if (!isOk) {
          val = arr.join('')
          e.target.value = val
          this.$data.text = val
        }
      }
      // axios
      //   .get('http://172.17.213.67:9005/commons/places?plcNm=' + e.target.value)
      //   .then(res => {
      //     const list = res.data
      //     if (list === undefined) {
      //       this.$data.valuesShowing = []
      //     } else {
      //       this.$data.valuesShowing = list.map(vo => vo.plcEnm)
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //THIS.$data.valuesShowing = this.$props.values.filter(a => a.toLowerCase().includes(e.target.value))
      // this.$emit('input', this.$data.text)
      this.$emit('update', e.target.value)

      if (e.target.value === '') {
        this.$emit('input', { cd: '', cdNm: '' })
      }
    },
    onClickHandler: function (e, cd, cdNm) {
      console.log('@@@@ enter onClickHandler')
      this.printLog('onClickHandler')
      let el = $(e.target)

      if (!el.hasClass('list-group-item')) {
        el = el.parent('.list-group-item').eq(0)
      }

      const arrBtn = $('#' + this.elemId).find('button.list-group-item')
      this.selectedIndex = arrBtn.index(el)

      this.$data.inputText = cdNm
      this.$emit('input', { cd: cd, cdNm: cdNm })
      el.blur()
    },
    onEnterClicked: function (e) {
      console.log('@@@@ enter onEnterClicked')
      console.log('@@@@ this.valuesShowing: ', this.valuesShowing)
      this.printLog('onEnterClicked ' + this.$data.selectedIndex)
      this.printLog('this.valuesShowing.length ' + this.valuesShowing.length)
      this.printLog(
        'onClickHandler e.target.dataset ' + JSON.stringify(e.target)
      )
      this.printLog(
        'onClickHandler e.target.value ' + JSON.stringify(e.target.value)
      )
      if (this.valuesShowing.length > 0) {
        const vo = this.$data.valuesShowing[this.$data.selectedIndex]
        this.$data.inputText = vo.cdNm
        this.$emit('input', { cd: vo.cd, cdNm: vo.cdNm })
        console.log('@@@@ e.target: ', e.target)
        console.log('@@@@ this.isEnter1: ', this.isEnter)
        this.isEnter = true
        e.target.blur()
      }
    },
    printLog (logs) {
      if (!this.isDebug) {
        return
      }
      console.log(logs)
    }
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
.list-group {
  position: absolute;
  min-width: 300px;
  max-height: 300px;
  border: 1px solid #d8d8d8;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
}

.input_error #autocomplete-form-input {
  border: 1px solid #f2084f !important;
}
</style>
