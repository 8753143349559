var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "autocomplete-form",
      on: {
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            return _vm.onKeydown($event)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            )
              return null
            return _vm.onKeyup($event)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onEnterClicked.apply(null, arguments)
          },
        ],
        focusin: function ($event) {
          _vm.visible = true
        },
        focusout: function ($event) {
          return _vm.onFocusout($event)
        },
      },
    },
    [
      _c("div", { staticClass: "autocomplete-form-input-elements" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputText,
              expression: "inputText",
            },
          ],
          ref: "autoInput",
          staticClass: "w-100",
          attrs: {
            id: "autocomplete-form-input",
            autocomplete: "off",
            readonly: _vm.readOnly,
            disabled: _vm.disabled,
            placeholder: _vm.plaholder,
            type: "text",
          },
          domProps: { value: _vm.inputText },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.inputText = $event.target.value
              },
              _vm.onInputHandler,
            ],
          },
        }),
      ]),
      _vm.focusForce
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.valuesShowing.length > 0,
                    expression: "valuesShowing.length > 0",
                  },
                ],
                class: { hide: (_vm.disabled && _vm.visible) || !_vm.visible },
                style: { maxHeight: "300px", position: "absolute" },
                attrs: { id: _vm.elemId },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "list-group list-group-flush autocomplete-form-items",
                    style: { maxHeight: "255px", position: "relative" },
                  },
                  _vm._l(_vm.valuesShowing.slice(0, -1), function (vo, index) {
                    return _c("button", {
                      key: index,
                      staticClass: "list-group-item list-group-item-action",
                      class: { active: _vm.selectedIndex == index },
                      attrs: { type: "button" },
                      domProps: { innerHTML: _vm._s(vo.displayText) },
                      on: {
                        click: function ($event) {
                          return _vm.onClickHandler($event, vo.cd, vo.cdNm)
                        },
                        mouseover: function ($event) {
                          _vm.selectedIndex = index
                        },
                      },
                    })
                  }),
                  0
                ),
                _c("button", {
                  staticClass: "list-group-item list-group-item-action",
                  class: {
                    active: _vm.selectedIndex == _vm.valuesShowing.length - 1,
                  },
                  style: { width: "300px", zIndex: "1" },
                  attrs: { type: "button" },
                  domProps: { innerHTML: _vm._s(_vm.getLastButtonText()) },
                  on: {
                    mouseover: function ($event) {
                      _vm.selectedIndex = _vm.valuesShowing.length - 1
                    },
                    click: function ($event) {
                      return _vm.onClickHandler(
                        $event,
                        _vm.valuesShowing[_vm.valuesShowing.length - 1].cd,
                        _vm.valuesShowing[_vm.valuesShowing.length - 1].cdNm
                      )
                    },
                  },
                }),
              ]
            ),
          ]
        : [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.valuesShowing.length > 0,
                    expression: "valuesShowing.length > 0",
                  },
                ],
                staticClass:
                  "list-group list-group-flush autocomplete-form-items",
                class: { hide: (_vm.disabled && _vm.visible) || !_vm.visible },
                attrs: { id: _vm.elemId },
              },
              _vm._l(_vm.valuesShowing, function (vo, index) {
                return _c("button", {
                  key: index,
                  staticClass: "list-group-item list-group-item-action",
                  class: { active: _vm.selectedIndex == index },
                  attrs: { type: "button" },
                  domProps: { innerHTML: _vm._s(vo.displayText) },
                  on: {
                    click: function ($event) {
                      return _vm.onClickHandler($event, vo.cd, vo.cdNm)
                    },
                    mouseover: function ($event) {
                      _vm.selectedIndex = index
                    },
                  },
                })
              }),
              0
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }